import { Mesh, PerspectiveCamera, PlaneBufferGeometry, Scene, WebGLRenderer } from 'three';
import { Material } from 'three/src/materials/Material';
import { Texture } from 'three/src/textures/Texture';


/**
 * The class for creating WebGLRenderer with a plain mesh which is responsive.
 *
 * @since 0.0.1
 */
export class Renderer {
  /**
   * The canvas element to render the scene to.
   */
  protected readonly canvas: HTMLCanvasElement;

  /**
   * A WebGLRenderer instance.
   */
  protected readonly renderer: WebGLRenderer;

  /**
   * A Mesh instance.
   */
  protected readonly mesh: Mesh;

  /**
   * A PerspectiveCamera instance.
   */
  protected readonly camera: PerspectiveCamera;

  /**
   * A Scene instance.
   */
  protected readonly scene = new Scene();

  /**
   * A geometry.
   */
  protected readonly geometry = new PlaneBufferGeometry( 1, 1 );

  /**
   * The ResponsiveMesh constructor.
   *
   * @param canvas   - A canvas element.
   * @param material - A Material instance.
   */
  constructor( canvas: HTMLCanvasElement, material: Material | Material[] ) {
    this.renderer = this.createRenderer( canvas );
    this.canvas   = canvas;
    this.camera   = this.createCamera();
    this.mesh     = new Mesh( this.geometry, material );

    this.scene.add( this.mesh );
  }

  /**
   * Decode textures beforehand.
   *
   * @param textures - Textures to initialize.
   */
  decode( textures: Texture[] ): void {
    textures.forEach( texture => {
      this.renderer.initTexture( texture );
    } );
  }

  /**
   * Renders the scene to the canvas.
   */
  render(): void {
    this.renderer.render( this.scene, this.camera );
  }

  /**
   * Sets the dimension.
   *
   * @param width  - Width.
   * @param height - Height.
   */
  setSize( width: number, height: number ): void {
    const aspect = ( width / height ) || 1;
    const [ viewWidth, viewHeight ] = this.computeViewDimension( aspect );

    this.mesh.scale.set( viewWidth, viewHeight, 1 );
    this.renderer.setSize( width, height );
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
  }

  /**
   * Creates and initializes the renderer.
   *
   * @param canvas - A canvas element to render the scene to.
   *
   * @return A created WebGLRenderer instance.
   */
  protected createRenderer( canvas: HTMLCanvasElement ): WebGLRenderer {
    const renderer = new WebGLRenderer( { canvas } );
    renderer.setPixelRatio( window.devicePixelRatio );
    return renderer;
  }

  /**
   * Creates a camera.
   *
   * @return A created PerspectiveCamera instance.
   */
  protected createCamera(): PerspectiveCamera {
    const camera = new PerspectiveCamera( 45, 1, 1, 10000 );
    camera.position.z = 50;
    return camera;
  }

  /**
   * Computes the view dimension so that the mesh fills up the camera.
   *
   * @param aspect - Aspect ratio.
   */
  protected computeViewDimension( aspect: number ): [ number, number ] {
    const { camera } = this;
    const fovRad = ( camera.fov * Math.PI ) / 180;
    const height = Math.abs( camera.position.z * Math.tan( fovRad / 2 ) * 2 );
    return [ height * aspect, height ];
  }
}